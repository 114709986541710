import { Navigate, Route, Routes } from "react-router-dom";
import { useSelector } from "react-redux";
import AuthError from "./AuthError";
import LoginComponent from "./Authorization/LoginComponent";
import WelcomeCallback from "./WelcomeCallback";
import loadable from "@loadable/component";
import LoadingOrError from "./LoadingOrError";
import { useAuth0 } from "@auth0/auth0-react";
import Loading from "../components/Loading";
import { security } from "../auth/security";
import SamlLogoutComponent from "./Authorization/SamlLogoutComponent";
import PasswordlessLogin from "./Authorization/PasswordlessLogin";
import { telemetryBeginRoute } from "../functions/telemetry";
import FeatureComponent from "../components/FeatureComponent";
import { LogoutComponent } from "./Authorization/LogoutComponent";
import RegisterPage from "./RegisterPage";
import InvalidUrlPage from "./InvalidUrlPage";
import DemoAccounts from "./Modules/SHI/DemoAccounts";
import ErrorPage from "./ErrorPage";
import ReactRouterClassWrapper from "../components/ReactRouterClassWrapper";
import SetWindowLocation from "../components/SetWindowLocation";
import ArchbeeShiInternal from "../components/ArchbeeShiInternal";
import { ServiceReport } from "./Modules/SHI/ServiceReports/ServiceReport";
import { AddressFieldTestPage } from "./Widgets/AddressFieldTestPage";
import { AwsGovernanceReports } from "./Modules/Cloud/AWS/AwsSecurityCompliance/AwsGovernanceReports";
import CustomerExperienceTwoColumnDashboard from "../v2/src/dashboards/CustomerExperienceTwoColumnDashboard";
import { AzureGovernanceReports } from "./Modules/Cloud/Azure/AzureGovernanceReports";
import { UserFlow } from "../v2/src/components/UserFlow";
import SuccessRequestCallback from "./SuccessRequestCallback";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ThemeProvider as EmotionThemeProvider } from "@emotion/react";
import { AppendIfPrefixExists } from "../modules/sitePaths";
import ProjectDetailsV2 from "@pages/services/projects/ProjectDetailsV2";
import { FeatureDefinitions } from "shared-ui/src/utils/FeatureDefinitions";
import { useThemeMode } from "shared-ui/src/theme/ThemeModeProviderContext";
import ErrorBoundary from "../IndexErrorBoundary";
import { detect } from "detect-browser";
import React from "react";

const ServicesBillingPage = loadable(
  () => import("../v2/src/pages/services/invoices/ServicesBillingPage"),
  {
    fallback: <LoadingOrError loading />,
  }
);

const HomeFeatureFlagged = loadable(() => import("./Home"), {
  fallback: <LoadingOrError loading />,
});

const ServiceRequestDetails = loadable(
  () => import("../v2/src/pages/service-requests/ServiceRequestDetails"),
  {
    fallback: <LoadingOrError loading />,
  }
);

const Monitoring = loadable(() => import("./Monitoring"), {
  fallback: <LoadingOrError loading />,
});


const MyCustomers = loadable(() => import("./MyCustomers"), {
  fallback: <LoadingOrError loading />,
});
const AzureReport = loadable(
  () => import("./Modules/Cloud/Azure/AzureReport"),
  {
    fallback: <LoadingOrError loading />,
  }
);
const AzureAllUpReport = loadable(
  () => import("./Modules/Cloud/Azure/AzureAllUpReport"),
  {
    fallback: <LoadingOrError loading />,
  }
);
const DatadogReportPage = loadable(
  () => import("./Modules/Cloud/Datadog/DatadogReportPage"),
  {
    fallback: <LoadingOrError loading />,
  }
);

const IdcsReport = loadable(
  () => import("./Modules/AssetManagement/IntegrationCenter/IdcsReport"),
  {
    fallback: <LoadingOrError loading />,
  }
);

const AwsMonitoring = loadable(
  () => import("./Modules/Cloud/AWS/AwsMonitoring"),
  {
    fallback: <LoadingOrError loading />,
  }
);
const AzureMonitoring = loadable(
  () => import("./Modules/Cloud/Azure/AzureMonitoring"),
  {
    fallback: <LoadingOrError loading />,
  }
);
const Office365Backup = loadable(
  () => import("./Modules/Cloud/Office365/Office365Backup"),
  {
    fallback: <LoadingOrError loading />,
  }
);
const DeviceList = loadable(() => import("./Modules/MySupport/DeviceList"), {
  fallback: <LoadingOrError loading />,
});
const EndUser = loadable(
  () => import("./Modules/DeviceManagementV2/EndUserDevices"),
  {
    fallback: <LoadingOrError loading />,
  }
);
const ITKnowledgeBase = loadable(
  () => import("./Modules/SupportCenter/ITKnowledgeBase"),
  {
    fallback: <LoadingOrError loading />,
  }
);
const Settings = loadable(() => import("./Modules/Settings/Settings"), {
  fallback: <LoadingOrError loading />,
});
const DownloadAttachment = loadable(
  () => import("../components/DownloadAttachment"),
  {
    fallback: <LoadingOrError loading />,
  }
);
const AwsAccounts = loadable(
  () => import("./Modules/Cloud/AWS/AwsAccounts/AwsAccounts"),
  {
    fallback: <LoadingOrError loading />,
  }
);
const AwsSimpleRecommendations = loadable(
  () =>
    import(
      "./Modules/Cloud/AWS/AwsSimpleRecommendations/AwsSimpleRecommendations"
    ),
  {
    fallback: <LoadingOrError loading />,
  }
);
const AwsCostAllocationTags = loadable(
  () => import("./Modules/Cloud/AWS/AwsCostAllocationTags"),
  {
    fallback: <LoadingOrError loading />,
  }
);
const AwsInvoiceList = loadable(
  () => import("./Modules/Cloud/AWS/AwsInvoiceList"),
  {
    fallback: <LoadingOrError loading />,
  }
);
const AzureAccounts = loadable(
  () => import("./Modules/Cloud/Azure/AzureAccounts"),
  {
    fallback: <LoadingOrError loading />,
  }
);
const AzureInvoiceList = loadable(
  () => import("./Modules/Cloud/Azure/AzureInvoiceList"),
  {
    fallback: <LoadingOrError loading />,
  }
);
const AzureBackup = loadable(
  () => import("./Modules/Cloud/Azure/AzureBackup"),
  {
    fallback: <LoadingOrError loading />,
  }
);
const AzureSecurityCompliance = loadable(
  () =>
    import(
      "./Modules/Cloud/Azure/AzureSecurityCompliance/AzureSecurityCompliance"
    ),
  {
    fallback: <LoadingOrError loading />,
  }
);
const Office365Accounts = loadable(
  () => import("./Modules/Cloud/Office365/Office365Accounts"),
  {
    fallback: <LoadingOrError loading />,
  }
);
const Office365InvoiceList = loadable(
  () => import("./Modules/Cloud/Office365/Office365InvoiceList"),
  {
    fallback: <LoadingOrError loading />,
  }
);
const TeamsManagement = loadable(
  () => import("@pages/teams-management/TeamsManagement"),
  {
    fallback: <LoadingOrError loading />,
  }
);
const Projects = loadable(() => import("../v2/src/pages/services/Projects"), {
  fallback: <LoadingOrError loading />,
});

const MyProjectList = loadable(() => import("./Modules/SHI/MyProjectList"), {
  fallback: <LoadingOrError loading />,
});
const MyAccountsList = loadable(() => import("./Modules/SHI/MyAccountsList"), {
  fallback: <LoadingOrError loading />,
});
const ContractMappings = loadable(
  () => import("./Modules/SHI/ContractMappings"),
  {
    fallback: <LoadingOrError loading />,
  }
);

const ContractDetailPage = loadable(
  () => import("./Modules/Services/ContractDetailsPage"),
  {
    fallback: <LoadingOrError loading />,
  }
);

const IntegrationCenterOrder = loadable(
  () =>
    import(
      "@features/assets-feature/components/warehouse-orders/WarehouseIntegrationOrder"
    ),
  {
    fallback: <LoadingOrError loading />,
  }
);

const WarehouseOrders = loadable(
  () => import("../v2/src/pages/assets/WarehouseOrdersPage"),
  {
    fallback: <LoadingOrError loading />,
  }
);
const WarehouseOrder = loadable(
  () =>
    import(
      "@features/assets-feature/components/warehouse-orders/WarehouseTransferOrder"
    ),
  {
    fallback: <LoadingOrError loading />,
  }
);
const WarehouseOrderDetail = loadable(
  () => import("../v2/src/pages/assets/WarehouseOrderDetailPage"),
  { fallback: <LoadingOrError loading /> }
);
const DemoShipping = loadable(
  () => import("./Modules/AssetManagement/Warehouse/DemoShipping"),
  {
    fallback: <LoadingOrError loading />,
  }
);
const OrganizationProfile = loadable(
  () => import("./Modules/Profiles/OrganizationProfile"),
  {
    fallback: <LoadingOrError loading />,
  }
);
const CloudReports = loadable(() => import("./Modules/SHI/CloudReports"), {
  fallback: <LoadingOrError loading />,
});

const Integrations = loadable(() => import("./Modules/Settings/Integrations"), {
  fallback: <LoadingOrError loading />,
});
const AzureSimpleRecommendations = loadable(
  () =>
    import(
      "./Modules/Cloud/Azure/AzureSimpleRecommendations/AzureSimpleRecommendations"
    ),
  {
    fallback: <LoadingOrError loading />,
  }
);
const MarketplaceAccounts = loadable(
  () => import("./Modules/SHI/MarketplaceAccounts"),
  {
    fallback: <LoadingOrError loading />,
  }
);
const RackAndStackOrder = loadable(
  () =>
    import(
      "@features/assets-feature/components/warehouse-orders/RackAndStackOrder"
    ),
  {
    fallback: <LoadingOrError loading />,
  }
);

const Blueprints = loadable(
  () => import("./Modules/Cloud/Blueprints/Blueprints"),
  {
    fallback: <LoadingOrError loading />,
  }
);
const GcpInvoiceList = loadable(
  () => import("./Modules/Cloud/GCP/GcpInvoiceList"),
  {
    fallback: <LoadingOrError loading />,
  }
);
const AccountView = loadable(() => import("./Modules/SHI/AccountView"));
const GcpProjects = loadable(() => import("./Modules/Cloud/GCP/GcpProjects"), {
  fallback: <LoadingOrError loading />,
});
const CostCenters = loadable(() => import("./Modules/Settings/CostCenters"), {
  fallback: <LoadingOrError loading />,
});

const Contracts = loadable(
  () => import("../v2/src/pages/services/contracts/ContractsPage"),
  {
    fallback: <LoadingOrError loading />,
  }
);

const ServiceRequestMappings = loadable(
  () => import("./Modules/SHI/ServiceRequestMappings"),
  {
    fallback: <LoadingOrError loading />,
  }
);
const SubmitRequest = loadable(
  () => import("./Modules/MySupport/SubmitRequest"),
  {
    fallback: <LoadingOrError loading />,
  }
);
const UserManagement = loadable(
  () => import("./Modules/Settings/UserManagement"),
  {
    fallback: <LoadingOrError loading />,
  }
);
const UserProfile = loadable(() => import("./Modules/Profiles/UserProfile"), {
  fallback: <LoadingOrError loading />,
});
const SetupWizard = loadable(() => import("../SetUpWizard/SetupWizard"), {
  fallback: <LoadingOrError loading />,
});

const AwsReport = loadable(
  () => import("../containers/Modules/Cloud/AWS/AwsReport"),
  {
    fallback: <LoadingOrError loading />,
  }
);

const GcpReport = loadable(
  () => import("../containers/Modules/Cloud/GCP/GcpReport"),
  {
    fallback: <LoadingOrError loading />,
  }
);

const MultiCloudReport = loadable(
  () => import("../containers/MultiCloudReport"),
  {
    fallback: <LoadingOrError loading />,
  }
);

const ContractsPage = loadable(
  () => import("../v2/src/pages/services/contracts/ContractsPage"),
  {
    fallback: <LoadingOrError loading />,
  }
);

const CiscoRenewals = loadable(
  () => import("./Modules/SHI/Cisco/Components/CiscoRenewals"),
  {
    fallback: <LoadingOrError loading />,
  }
);

const CiscoIba = loadable(
  () => import("./Modules/SHI/Cisco/Components/CiscoIba"),
  {
    fallback: <LoadingOrError loading />,
  }
);

const Labs = loadable(() => import("@pages/labs/Labs"), {
  fallback: <LoadingOrError loading />,
});

const UserOnDemandLabsManagement = loadable(
  () => import("@pages/labs/UserOnDemandLabRequestsManagement"),
  {
    fallback: <LoadingOrError loading />,
  }
);

const SubmitRequestHandler = loadable(
  () => import("./Modules/MySupport/SubmitRequestHandler"),
  {
    fallback: <LoadingOrError loading />,
  }
);

const CloudOnboardingReport = loadable(
  () => import("./Modules/SHI/CloudOnboardingReport"),
  {
    fallback: <LoadingOrError loading />,
  }
);

const CiscoOverviewReport = loadable(
  () => import("@pages/assets/OverviewReport"),
  {
    fallback: <LoadingOrError loading />,
  }
);

const AssetInventory = loadable(
  () => import("@pages/assets/AssetInventoryPage"),
  {
    fallback: <LoadingOrError loading />,
  }
);
const AssetLocations = loadable(
  () => import("@pages/assets/AssetLocationsPage"),
  {
    fallback: <LoadingOrError loading />,
  }
);
const AssetContracts = loadable(
  () => import("@pages/assets/AssetContractsPage"),
  {
    fallback: <LoadingOrError loading />,
  }
);

const AssetProductLifecycle = loadable(
  () => import("@pages/assets/AssetLifecyclePage"),
  {
    fallback: <LoadingOrError loading />,
  }
);

const AssetProductCatalog = loadable(
  () => import("@pages/assets/AssetProductCatalogPage"),
  {
    fallback: <LoadingOrError loading />,
  }
);

const WarehouseStock = loadable(
  () => import("@pages/assets/WarehouseStockPage"),
  {
    fallback: <LoadingOrError loading />,
  }
);

const CustomPbi = loadable(
  () => import("@pages/self-service-reporting/CustomPbi"),
  {
    fallback: <LoadingOrError loading />,
  }
);

const ReportCenter = loadable(
  () => import("@pages/self-service-reporting/ReportCenter"),
  {
    fallback: <LoadingOrError loading />,
  }
);

const NewReport = loadable(
  () => import("@pages/self-service-reporting/NewReport"),
  {
    fallback: <LoadingOrError loading />,
  }
);

const Report = loadable(() => import("@pages/self-service-reporting/Report"), {
  fallback: <LoadingOrError loading />,
});

const EditReport = loadable(
  () => import("@pages/self-service-reporting/EditReportPage"),
  {
    fallback: <LoadingOrError loading />,
  }
);

const CloudConsumptionReport = loadable(
  () => import("@pages/report-center/CloudConsumptionReportPage"),
  {
    fallback: <LoadingOrError loading />,
  }
);

const CloudGovernanceReport = loadable(
  () => import("@pages/report-center/CloudGovernanceReportPage"),
  {
    fallback: <LoadingOrError loading />,
  }
);

const ReportCenterServiceReport = loadable(
  () => import("@pages/report-center/ServiceReportPage"),
  {
    fallback: <LoadingOrError loading />,
  }
);

const NewsCenter = loadable(() => import("@pages/news-center/NewsCenter"), {
  fallback: <LoadingOrError loading />,
});

const RequestLandingPage = loadable(
  () => import("@pages/support-center/ServiceRequestLandingPage"),
  {
    fallback: <LoadingOrError loading />,
  }
);

const ServiceRequestsPage = loadable(
  () => import("../v2/src/pages/service-requests/ServiceRequestsPage"),
  {
    fallback: <LoadingOrError loading />,
  }
);

const TagGroupsPage = loadable(() => import("@pages/cloud/TagGroupsPage"), {
  fallback: <LoadingOrError loading />,
});

const TagGroupReportsPage = loadable(
  () => import("@pages/cloud/TagGroupReportsPage"),
  {
    fallback: <LoadingOrError loading />,
  }
);

const AssessmentsPage = loadable(
  () => import("@pages/assessments/AssessmentsPage"),
  {
    fallback: <LoadingOrError loading />,
  }
);

const AssessmentViewPage = loadable(
  () => import("@pages/assessments/AssessmentViewPage"),
  {
    fallback: <LoadingOrError loading />,
  }
);

const CloudAccounts = loadable(() => import("@pages/cloud/Accounts"), {
  fallback: <LoadingOrError loading />,
});

function App() {
  const { labelInfo } = useSelector((state) => state.site);
  const { isLoading, error, getAccessTokenSilently } = useAuth0();
  const { currentTheme } = useThemeMode();

  security.setAccessTokenSilently(getAccessTokenSilently);

  const browserType = detect();

  const browserHandler = {
    default: () => <h4>Unknown Error Encountered!</h4>,
    ie: () => (
      <h4>
        Internet Explorer is not supported, please use a modern browser such as
        Edge, Firefox, Chrome, or Safari.
      </h4>
    ),
  };

  browserHandler.default.displayName = "BrowserHandlerDefault";
  browserHandler.ie.displayName = "BrowserHandlerIE";

  if (isLoading) {
    return <Loading />;
  }

  if (error) {
    return (
      <ReactRouterClassWrapper>
        {(props) => <AuthError labelInfo={labelInfo} {...props} />}
      </ReactRouterClassWrapper>
    );
  }

  return (
    <EmotionThemeProvider theme={currentTheme}>
      <ThemeProvider theme={currentTheme}>
        <CssBaseline />
        <UserFlow />
        <ErrorBoundary
          browserType={browserType}
          browserHandler={{ browserHandler }}
        >
          <Routes>
            <>
              <Route
                path={"/:prefix?/report-center/customPbi"}
                element={
                  <FeatureComponent
                    authRequired
                    showDashboard
                    path="/:prefix?/report-center/customPbi"
                    feature={FeatureDefinitions.Unknown_Unknown}
                  >
                    <CustomPbi />
                  </FeatureComponent>
                }
              />
              <Route
                path={"/:prefix?/report-center"}
                element={
                  <FeatureComponent
                    authRequired
                    showDashboard
                    path="/:prefix?/report-center"
                    feature={FeatureDefinitions.Unknown_Unknown}
                  >
                    <ReportCenter />
                  </FeatureComponent>
                }
              />
              <Route
                path={"/:prefix?/report-center/cloud-consumption"}
                element={
                  <FeatureComponent
                    authRequired
                    showDashboard
                    path="/:prefix?/report-center/report/cloud-consumption"
                    feature={
                      FeatureDefinitions.MulticloudConsumption_CostandUsageReporting
                    }
                  >
                    <CloudConsumptionReport />
                  </FeatureComponent>
                }
              />
              <Route
                path={"/:prefix?/report-center/cloud-governance"}
                element={
                  <FeatureComponent
                    authRequired
                    showDashboard
                    path="/:prefix?/report-center/report/cloud-governance"
                    feature={FeatureDefinitions.Other_Other}
                  >
                    <CloudGovernanceReport />
                  </FeatureComponent>
                }
              />
              <Route
                path={"/:prefix?/report-center/service-report"}
                element={
                  <FeatureComponent
                    authRequired
                    showDashboard
                    path="/:prefix?/report-center/report/service-report"
                    feature={
                      FeatureDefinitions.ServiceRequests_AccountServiceRequests
                    }
                  >
                    <ReportCenterServiceReport />
                  </FeatureComponent>
                }
              />
              <Route
                path={"/:prefix?/report-center/create/:datasetId"}
                element={
                  <FeatureComponent
                    authRequired
                    showDashboard
                    path="/:prefix?/report-center/create/:datasetId"
                    feature={FeatureDefinitions.Unknown_Unknown}
                  >
                    <NewReport />
                  </FeatureComponent>
                }
              />
              <Route
                path={"/:prefix?/report-center/report/:reportId"}
                element={
                  <FeatureComponent
                    authRequired
                    showDashboard
                    path="/:prefix?/report-center/report/:reportId"
                    feature={FeatureDefinitions.Unknown_Unknown}
                  >
                    <Report />
                  </FeatureComponent>
                }
              />
              <Route
                path={"/:prefix?/report-center/edit/:reportId"}
                element={
                  <FeatureComponent
                    authRequired
                    showDashboard
                    path="/:prefix?/report-center/edit/:reportId"
                    feature={FeatureDefinitions.Unknown_Unknown}
                  >
                    <EditReport />
                  </FeatureComponent>
                }
              />
            </>

            <Route
              path={"/:prefix?/news-center"}
              element={
                <FeatureComponent
                  authRequired
                  showDashboard
                  path="/:prefix?/news-center"
                  feature={FeatureDefinitions.CustomerAdvisory_NewsCenter}
                >
                  <NewsCenter />
                </FeatureComponent>
              }
            />
            <Route
              path={"/:prefix?/news-center/:id"}
              element={
                <FeatureComponent
                  authRequired
                  showDashboard
                  path="/:prefix?/news-center/:id"
                  feature={FeatureDefinitions.CustomerAdvisory_NewsCenter}
                >
                  <NewsCenter />
                </FeatureComponent>
              }
            />
            <Route
              path="/valuestreams/asg"
              element={
                <SetWindowLocation
                  location={
                    "https://shiandms.sharepoint.com/sites/SHIOne/SitePages/ASG-Team.aspx"
                  }
                />
              }
            />
            <Route
              path="/valuestreams/msp"
              element={
                <SetWindowLocation
                  location={
                    "https://shiandms.sharepoint.com/sites/SHIOne/SitePages/MSP-Team.aspx"
                  }
                />
              }
            />
            <Route
              path="/valuestreams/revenue"
              element={
                <SetWindowLocation
                  location={
                    "https://shiandms.sharepoint.com/sites/SHIOne/SitePages/Revenue-Team.aspx"
                  }
                />
              }
            />
            <Route
              path="/valuestreams/cisco"
              element={
                <SetWindowLocation
                  location={
                    "https://shiandms.sharepoint.com/sites/SHIOne/SitePages/Cisco-Team.aspx"
                  }
                />
              }
            />
            <Route
              path="/passwordlessLogin/:emailAddress/"
              loader={() =>
                telemetryBeginRoute({
                  path: "/passwordlessLogin/:emailAddress/",
                  feature: FeatureDefinitions.Auth_Auth,
                })
              }
              element={<PasswordlessLogin targetPath="/dashboards/home" />}
            />
            <Route
              path="/:prefix?/logout"
              loader={() =>
                telemetryBeginRoute({
                  path: "/:prefix?/logout",
                  feature: FeatureDefinitions.Auth_Auth,
                })
              }
              element={<LogoutComponent labelInfo={labelInfo} />}
            />
            <Route
              path="/:prefix?/login"
              element={
                <FeatureComponent
                  path={"/:prefix?/login"}
                  feature={FeatureDefinitions.Auth_Auth}
                >
                  <LoginComponent labelInfo={labelInfo} />
                </FeatureComponent>
              }
            />
            <Route
              path="/:prefix?/logout/saml"
              element={<SamlLogoutComponent />}
            />
            <Route
              path="/welcomeCallback"
              element={
                <FeatureComponent
                  path={"/welcomeCallback"}
                  feature={FeatureDefinitions.Auth_OnboardingWizard}
                >
                  <ReactRouterClassWrapper>
                    {(props) => <WelcomeCallback {...props} />}
                  </ReactRouterClassWrapper>
                </FeatureComponent>
              }
            />
            <Route
              path="/success-request-callback"
              element={
                <FeatureComponent
                  path={"/success-request-callback"}
                  feature={
                    FeatureDefinitions.ServiceRequests_UserServiceRequestSubmission
                  }
                >
                  <SuccessRequestCallback />
                </FeatureComponent>
              }
            />
            <Route
              path="/:prefix?/dashboards/home"
              element={
                <FeatureComponent
                  authRequired
                  showDashboard
                  path="/:prefix?/dashboards/home"
                  feature={FeatureDefinitions.Dashboard_HomeDashboard}
                >
                  <HomeFeatureFlagged labelInfo={labelInfo} />
                </FeatureComponent>
              }
            />
            <Route
              path="/:prefix?/dashboards/home"
              element={
                <FeatureComponent
                  authRequired
                  showDashboard
                  path="/:prefix?/home/dashboards"
                  feature={FeatureDefinitions.Dashboard_HomeDashboard}
                >
                  <HomeFeatureFlagged labelInfo={labelInfo} />
                </FeatureComponent>
              }
            />
            <Route
              path="/:prefix?/dashboards/monitoring"
              element={
                <FeatureComponent
                  authRequired
                  showDashboard
                  path="/:prefix?/dashboards/monitoring"
                  feature={FeatureDefinitions.Monitoring_CloudMonitoringAlerts}
                >
                  <Monitoring />
                </FeatureComponent>
              }
            />
            <Route
              path="/:prefix?/dashboards/multicloud"
              element={
                <FeatureComponent
                  authRequired
                  showDashboard
                  path="/:prefix?/dashboards/multicloud"
                  feature={
                    FeatureDefinitions.MulticloudConsumption_CostandUsageReporting
                  }
                >
                  <MultiCloudReport />
                </FeatureComponent>
              }
            />
            <Route
              path="/:prefix?/dashboards/mycustomers"
              element={
                <FeatureComponent
                  authRequired
                  showDashboard
                  path="/:prefix?/dashboards/mycustomers"
                  feature={FeatureDefinitions.SHIInternal_MyCustomerDashboard}
                >
                  <MyCustomers />
                </FeatureComponent>
              }
            />
            <Route
              path="/:prefix?/support-center/new-request/*"
              element={
                <FeatureComponent
                  authRequired
                  showDashboard
                  path="/:prefix?/support-center/new-request/*"
                  feature={
                    FeatureDefinitions.ServiceRequests_UserServiceRequestSubmission
                  }
                >
                  <RequestLandingPage />
                </FeatureComponent>
              }
            />
            <Route
              path="/:prefix?/support-center/new-request/:ticketTypeName/:formName"
              element={
                <FeatureComponent
                  authRequired
                  showDashboard
                  path="/:prefix?/support-center/new-request/:ticketTypeName/:formName"
                  feature={
                    FeatureDefinitions.ServiceRequests_UserServiceRequestSubmission
                  }
                >
                  <SubmitRequestHandler />
                </FeatureComponent>
              }
            />
            <Route
              path="/:prefix?/support-center/mydevices"
              element={
                <FeatureComponent
                  authRequired
                  showDashboard
                  path="/:prefix?/support-center/mydevices"
                  feature={FeatureDefinitions.EndUserDevices_DeviceManagement}
                >
                  <DeviceList />
                </FeatureComponent>
              }
            />
            <Route
              path="/:prefix?/support-center/submit-request/:type?"
              element={
                <FeatureComponent
                  authRequired
                  showDashboard
                  path="/:prefix?/support-center/submit-request/:type?"
                  feature={
                    FeatureDefinitions.ServiceRequests_UserServiceRequestSubmission
                  }
                >
                  <SubmitRequest />
                </FeatureComponent>
              }
            />
            <Route
              path="/:prefix?/assets-dashboard"
              element={
                <FeatureComponent
                  path={"/:prefix?/assets-dashboard"}
                  feature={FeatureDefinitions.Unknown_Unknown}
                  authRequired
                  showDashboard
                >
                  <CustomerExperienceTwoColumnDashboard
                    header={"Assets"}
                    dashboardId={"assets"}
                  />
                </FeatureComponent>
              }
            />
            <Route
              path="/:prefix?/assets/asset-inventory"
              element={
                <FeatureComponent
                  path={"/:prefix?/assets/asset-inventory"}
                  feature={FeatureDefinitions.AssetManagement_AssetInventory}
                  authRequired
                  showDashboard
                >
                  <AssetInventory />
                </FeatureComponent>
              }
            />
            <Route
              path="/:prefix?/assets/locations"
              element={
                <FeatureComponent
                  path={"/:prefix?/assets/locations"}
                  feature={FeatureDefinitions.AssetManagement_AssetLocations}
                  authRequired
                  showDashboard
                >
                  <AssetLocations />
                </FeatureComponent>
              }
            />
            <Route
              path="/:prefix?/assets/product-catalog/products"
              element={
                <FeatureComponent
                  path={"/:prefix?/assets/product-catalog/products"}
                  feature={
                    FeatureDefinitions.AssetManagement_AssetProductCatalog
                  }
                  authRequired
                  showDashboard
                >
                  <AssetProductCatalog />
                </FeatureComponent>
              }
            />
            <Route
              path="/:prefix?/assets/product-catalog/lifecycle"
              element={
                <FeatureComponent
                  path={"/:prefix?/assets/product-catalog/lifecycle"}
                  feature={FeatureDefinitions.AssetManagement_AssetLifecycle}
                  authRequired
                  showDashboard
                >
                  <AssetProductLifecycle />
                </FeatureComponent>
              }
            />
            <Route
              path="/:prefix?/assets/vendor-contracts"
              element={
                <FeatureComponent
                  path={"/:prefix?/assets/vendor-contracts"}
                  feature={FeatureDefinitions.AssetManagement_AssetContracts}
                  authRequired
                  showDashboard
                >
                  <AssetContracts />
                </FeatureComponent>
              }
            />
            <Route
              path="/:prefix?/assets/managed-devices/end-user"
              element={
                <FeatureComponent
                  authRequired
                  showDashboard
                  path="/:prefix?/assets/managed-devices/end-user"
                  feature={FeatureDefinitions.EndUserDevices_DeviceManagement}
                >
                  <EndUser />
                </FeatureComponent>
              }
            />
            <Route
              path="/:prefix?/assets/warehouse/stock"
              element={
                <FeatureComponent
                  authRequired
                  showDashboard
                  path="/:prefix?/assets/warehouse/stock"
                  feature={
                    FeatureDefinitions.HardwareAssets_AXWarehouseInventory
                  }
                >
                  <WarehouseStock />
                </FeatureComponent>
              }
            />
            <Route
              path="/:prefix?/assets/warehouse/orders"
              element={
                <FeatureComponent
                  authRequired
                  showDashboard
                  path="/:prefix?/assets/warehouse/orders"
                  feature={
                    FeatureDefinitions.HardwareAssets_AXWarehouseInventory
                  }
                >
                  <WarehouseOrders />
                </FeatureComponent>
              }
            />
            <Route
              path="/:prefix?/assets/warehouse/orders/:id"
              element={
                <FeatureComponent
                  authRequired
                  showDashboard
                  path="/:prefix?/assets/warehouse/orders/:id"
                  feature={
                    FeatureDefinitions.HardwareAssets_AXWarehouseInventory
                  }
                >
                  <WarehouseOrderDetail />
                </FeatureComponent>
              }
            />
            <Route
              path="/:prefix?/assets/warehouse/orders/transfer/:id"
              element={
                <FeatureComponent
                  authRequired
                  showDashboard
                  path="/:prefix?/assets/warehouse/orders/transfer/:id"
                  feature={
                    FeatureDefinitions.HardwareAssets_AXWarehouseInventory
                  }
                >
                  <WarehouseOrder />
                </FeatureComponent>
              }
            />
            <Route
              path="/:prefix?/assets/warehouse/orders/ipldemotracking/:id/:trackNum"
              element={<DemoShipping />}
            />
            <Route
              path="/:prefix?/assets/warehouse/orders/integration-center/:id"
              element={
                <FeatureComponent
                  authRequired
                  showDashboard
                  path="/:prefix?/assets/warehouse/orders/integration-center/:id"
                  feature={FeatureDefinitions.HardwareAssets_AXWarehouseOrders}
                >
                  <IntegrationCenterOrder />
                </FeatureComponent>
              }
            />
            <Route
              path="/:prefix?/assets/warehouse/orders/rack-and-stack/:id"
              element={
                <FeatureComponent
                  authRequired
                  showDashboard
                  path="/:prefix?/assets/warehouse/orders/rack-and-stack/:id"
                  feature={FeatureDefinitions.HardwareAssets_RackandStack}
                >
                  <RackAndStackOrder />
                </FeatureComponent>
              }
            />
            <Route
              path="/:prefix?/assets/warehouse/reports"
              element={
                <FeatureComponent
                  authRequired
                  showDashboard
                  feature={FeatureDefinitions.Other_Other}
                  path="/:prefix?/assets/warehouse/reports"
                >
                  <IdcsReport />
                </FeatureComponent>
              }
            />
            <Route
              path="/:prefix?/assets/cisco-overview"
              element={
                <FeatureComponent
                  authRequired
                  showDashboard
                  path="/:prefix?/assets/cisco-overview"
                  feature={FeatureDefinitions.Cisco_CiscoOverview}
                >
                  <CiscoOverviewReport />
                </FeatureComponent>
              }
            />
            <Route
              path="/:prefix?/cisco/full-inventory"
              element={
                <Navigate
                  to={{
                    pathname: AppendIfPrefixExists("/assets/asset-inventory"),
                  }}
                />
              }
            />
            <Route
              path="/:prefix?/cisco/contract-inventory/:contractNumber"
              element={
                <Navigate
                  to={{
                    pathname: AppendIfPrefixExists("/assets/asset-inventory"),
                  }}
                />
              }
            />
            <Route
              path="/:prefix?/cisco/site-inventory/:siteName/:siteAddress"
              element={
                <Navigate
                  to={{
                    pathname: AppendIfPrefixExists("/assets/asset-inventory"),
                  }}
                />
              }
            />
            <Route
              path="/:prefix?/cisco/lifecycle-report"
              element={
                <Navigate
                  to={{
                    pathname: AppendIfPrefixExists(
                      "/assets/product-catalog/lifecycle"
                    ),
                  }}
                />
              }
            />
            <Route
              path="/:prefix?/cisco/contracts"
              element={
                <Navigate
                  to={{
                    pathname: AppendIfPrefixExists("/assets/vendor-contracts"),
                  }}
                />
              }
            />
            <Route
              path="/:prefix?/cisco/site-location-report"
              element={
                <Navigate
                  to={{
                    pathname: AppendIfPrefixExists("/assets/locations"),
                  }}
                />
              }
            />
            <Route
              path="/:prefix?/support-center/requests"
              element={
                <FeatureComponent
                  authRequired
                  showDashboard
                  path="/:prefix?/support-center/requests"
                  feature={
                    FeatureDefinitions.ServiceRequests_AccountServiceRequests
                  }
                >
                  <ServiceRequestsPage />
                </FeatureComponent>
              }
            />
            <Route
              path="/:prefix?/support-center/requests/:id"
              element={
                <FeatureComponent
                  authRequired
                  showDashboard
                  path="/:prefix?/support-center/requests/:id"
                  feature={
                    FeatureDefinitions.ServiceRequests_AccountServiceRequests
                  }
                >
                  <ServiceRequestDetails />
                </FeatureComponent>
              }
            />
            <Route
              path="/:prefix?/services/contracts"
              element={
                <FeatureComponent
                  authRequired
                  showDashboard
                  path="/:prefix?/services/contracts"
                  feature={FeatureDefinitions.ProjectManagement_Contracts}
                >
                  <Contracts />
                </FeatureComponent>
              }
            />
            <Route
              path="/:prefix?/services/contracts-v2"
              element={
                <FeatureComponent
                  authRequired
                  showDashboard
                  path="/:prefix?/services/contracts-v2"
                  feature={FeatureDefinitions.ProjectManagement_Contracts}
                >
                  <ContractsPage />
                </FeatureComponent>
              }
            />
            <Route
              path="/:prefix?/services/projects/:projectId"
              element={
                <FeatureComponent
                  authRequired
                  showDashboard
                  path="/:prefix?/services/projects/:projectId"
                  feature={FeatureDefinitions.ProjectManagement_Projects}
                >
                  <ProjectDetailsV2 />
                </FeatureComponent>
              }
            />
            <Route
              path="/:prefix?/services/projects"
              element={
                <FeatureComponent
                  authRequired
                  showDashboard
                  path="/:prefix?/services/projects"
                  feature={FeatureDefinitions.ProjectManagement_Projects}
                >
                  <Projects />
                </FeatureComponent>
              }
            />
            <Route
              path="/:prefix?/services/invoices"
              element={
                <FeatureComponent
                  authRequired
                  showDashboard
                  path="/:prefix?/services/invoices"
                  feature={FeatureDefinitions.ProjectManagement_Invoices}
                >
                  <ServicesBillingPage />
                </FeatureComponent>
              }
            />
            <Route
              path="/:prefix?/services/billing"
              element={
                <FeatureComponent
                  authRequired
                  showDashboard
                  path="/:prefix?/services/billing"
                  feature={FeatureDefinitions.ProjectManagement_Invoices}
                >
                  <ServicesBillingPage />
                </FeatureComponent>
              }
            />
            <Route
              path="/:prefix?/shi-internal/projects"
              element={
                <FeatureComponent
                  authRequired
                  showDashboard
                  path="/:prefix?/shi-internal/projects"
                  feature={FeatureDefinitions.SHIInternal_Projects}
                >
                  <Projects shiInternal />
                </FeatureComponent>
              }
            />
            <Route
              path="/:prefix?/services/contracts/:contractId"
              element={
                <FeatureComponent
                  authRequired
                  showDashboard
                  path="/:prefix?/services/contracts/:contractId"
                  feature={FeatureDefinitions.ProjectManagement_Contracts}
                >
                  <ContractDetailPage />
                </FeatureComponent>
              }
            />
            <Route
              path="/:prefix?/support-center/it-knowledgebase"
              element={
                <FeatureComponent
                  authRequired
                  showDashboard
                  path="/:prefix?/support-center/it-knowledgebase"
                  feature={FeatureDefinitions.Knowledgebase_Knowledgebase}
                >
                  <ITKnowledgeBase />
                </FeatureComponent>
              }
            />
            <Route
              path="/:prefix?/cloud/accounts"
              element={
                <FeatureComponent
                  authRequired
                  showDashboard
                  path="/:prefix?/cloud/accounts"
                  feature={FeatureDefinitions.Unknown_Unknown}
                >
                  <CloudAccounts />
                </FeatureComponent>
              }
            />
            <Route
              path="/:prefix?/cloud/azure/backup"
              element={
                <FeatureComponent
                  authRequired
                  showDashboard
                  path="/:prefix?/cloud/azure/backup"
                  feature={FeatureDefinitions.Azure_Backup}
                >
                  <AzureBackup />
                </FeatureComponent>
              }
            />
            <Route
              path="/:prefix?/cloud/azure/blueprints"
              element={
                <FeatureComponent
                  authRequired
                  showDashboard
                  path="/:prefix?/cloud/azure/blueprints"
                  feature={FeatureDefinitions.Azure_Blueprints}
                >
                  <Blueprints platform="azure" />
                </FeatureComponent>
              }
            />
            <Route
              path="/:prefix?/cloud/azure/securitycompliance"
              element={
                <FeatureComponent
                  authRequired
                  showDashboard
                  path="/:prefix?/cloud/azure/securitycompliance"
                  feature={FeatureDefinitions.Azure_Governance}
                >
                  <AzureSecurityCompliance />
                </FeatureComponent>
              }
            />
            <Route
              path="/:prefix?/cloud/azure/governance"
              element={
                <FeatureComponent
                  authRequired
                  showDashboard
                  path="/:prefix?/cloud/azure/governance"
                  feature={FeatureDefinitions.Azure_Governance}
                >
                  <AzureGovernanceReports />
                </FeatureComponent>
              }
            />
            <Route
              path="/:prefix?/cloud/azure/recommendations"
              element={
                <FeatureComponent
                  authRequired
                  showDashboard
                  path="/:prefix?/cloud/azure/recommendations"
                  feature={FeatureDefinitions.Azure_Governance}
                >
                  <AzureSimpleRecommendations />
                </FeatureComponent>
              }
            />
            <Route
              path="/:prefix?/cloud/azure/reports/all-up/recommendations"
              element={
                <FeatureComponent
                  authRequired
                  showDashboard
                  path="/:prefix?/cloud/azure/reports/all-up/recommendations"
                  feature={FeatureDefinitions.Azure_Governance}
                >
                  {" "}
                  <AzureAllUpReport />
                </FeatureComponent>
              }
            />
            <Route
              path="/:prefix?/cloud/azure/reports"
              element={
                <FeatureComponent
                  authRequired
                  showDashboard
                  path="/:prefix?/cloud/azure/reports"
                  feature={FeatureDefinitions.Azure_CostandUsageReporting}
                >
                  <AzureReport />
                </FeatureComponent>
              }
            />
            <Route
              path="/:prefix?/shi-internal/cloudreports"
              element={
                <FeatureComponent
                  authRequired
                  showDashboard
                  path="/:prefix?/shi-internal/cloudreports"
                  feature={FeatureDefinitions.SHIInternal_CostandUsageReporting}
                >
                  <CloudReports />
                </FeatureComponent>
              }
            />
            <Route
              path="/:prefix?/shi-internal/cloudOnboardingReport"
              element={
                <FeatureComponent
                  authRequired
                  showDashboard
                  path="/:prefix?/shi-internal/cloudOnboardingReport"
                  feature={FeatureDefinitions.ServiceRequests_CloudOnboarding}
                >
                  <CloudOnboardingReport />
                </FeatureComponent>
              }
            />

            <Route
              path="/:prefix?/cloud/aws/reports"
              element={
                <FeatureComponent
                  authRequired
                  showDashboard
                  feature={FeatureDefinitions.AWS_CostandUsageReporting}
                  path="/:prefix?/cloud/aws/reports"
                >
                  <AwsReport />
                </FeatureComponent>
              }
            />
            <Route
              path="/:prefix?/cloud/aws/blueprints"
              element={
                <FeatureComponent
                  authRequired
                  showDashboard
                  feature={FeatureDefinitions.AWS_Blueprints}
                  path="/:prefix?/cloud/aws/blueprints"
                >
                  <Blueprints platform="aws" />
                </FeatureComponent>
              }
            />
            <Route
              path="/:prefix?/cloud/aws/accounts"
              element={
                <FeatureComponent
                  authRequired
                  showDashboard
                  feature={FeatureDefinitions.AWS_CloudAccountsandSubscriptions}
                  path="/:prefix?/cloud/aws/accounts"
                >
                  <AwsAccounts />
                </FeatureComponent>
              }
            />
            <Route
              path="/:prefix?/cloud/aws/cat"
              element={
                <FeatureComponent
                  authRequired
                  showDashboard
                  feature={FeatureDefinitions.AWS_CostAllocationTags}
                  path="/:prefix?/cloud/aws/cat"
                >
                  <AwsCostAllocationTags />
                </FeatureComponent>
              }
            />
            <Route
              path="/:prefix?/cloud/aws/invoices"
              element={
                <FeatureComponent
                  authRequired
                  showDashboard
                  feature={FeatureDefinitions.AWS_CloudInvoicing}
                  path="/:prefix?/cloud/aws/invoices"
                >
                  <AwsInvoiceList />
                </FeatureComponent>
              }
            />
            <Route
              path="/:prefix?/cloud/aws/monitoring/:id?"
              element={
                <FeatureComponent
                  authRequired
                  showDashboard
                  feature={FeatureDefinitions.AWS_CloudMonitoringAlerts}
                  path="/:prefix?/cloud/aws/monitoring/:id?"
                >
                  <AwsMonitoring />
                </FeatureComponent>
              }
            />
            <Route
              path="/:prefix?/cloud/aws/governance"
              element={
                <FeatureComponent
                  authRequired
                  showDashboard
                  feature={FeatureDefinitions.AWS_Governance}
                  path="/:prefix?/cloud/aws/governance"
                >
                  <AwsGovernanceReports />
                </FeatureComponent>
              }
            />
            <Route
              path="/:prefix?/cloud/aws/recommendations"
              element={
                <FeatureComponent
                  authRequired
                  showDashboard
                  feature={FeatureDefinitions.AWS_Governance}
                  path="/:prefix?/cloud/aws/recommendations"
                >
                  <AwsSimpleRecommendations />
                </FeatureComponent>
              }
            />
            <Route
              path="/:prefix?/cloud/azure/subscriptions"
              element={
                <FeatureComponent
                  authRequired
                  showDashboard
                  feature={
                    FeatureDefinitions.Azure_CloudAccountsandSubscriptions
                  }
                  path="/:prefix?/cloud/azure/subscriptions"
                >
                  <AzureAccounts />
                </FeatureComponent>
              }
            />
            <Route
              path="/:prefix?/cloud/azure/monitoring/:id?"
              element={
                <FeatureComponent
                  authRequired
                  showDashboard
                  feature={FeatureDefinitions.Azure_CloudMonitoringAlerts}
                  path="/:prefix?/cloud/azure/monitoring/:id?"
                >
                  <AzureMonitoring />
                </FeatureComponent>
              }
              feature={FeatureDefinitions.Azure_CloudMonitoringAlerts}
            />
            <Route
              path="/:prefix?/cloud/azure/invoices"
              element={
                <FeatureComponent
                  authRequired
                  showDashboard
                  feature={FeatureDefinitions.Azure_CloudInvoicing}
                  path="/:prefix?/cloud/azure/invoices"
                >
                  <AzureInvoiceList />
                </FeatureComponent>
              }
            />
            <Route
              path="/:prefix?/cloud/gcp/invoices"
              element={
                <FeatureComponent
                  authRequired
                  showDashboard
                  feature={FeatureDefinitions.GCP_CloudInvoicing}
                  path="/:prefix?/cloud/gcp/invoices"
                >
                  <GcpInvoiceList />
                </FeatureComponent>
              }
            />
            <Route
              path="/:prefix?/cloud/gcp/projects"
              element={
                <FeatureComponent
                  authRequired
                  showDashboard
                  feature={FeatureDefinitions.GCP_CloudAccountsandSubscriptions}
                  path="/:prefix?/cloud/gcp/projects"
                >
                  <GcpProjects />
                </FeatureComponent>
              }
            />
            <Route
              path="/:prefix?/cloud/gcp/reports"
              element={
                <FeatureComponent
                  authRequired
                  showDashboard
                  feature={FeatureDefinitions.GCP_CostandUsageReporting}
                  path="/:prefix?/cloud/gcp/reports"
                >
                  <GcpReport />
                </FeatureComponent>
              }
            />
            <Route
              path="/:prefix?/cloud/office365/backup"
              element={
                <FeatureComponent
                  authRequired
                  showDashboard
                  feature={FeatureDefinitions.Office365_SkykickBackup}
                  path="/:prefix?/cloud/office365/backup"
                >
                  <Office365Backup />
                </FeatureComponent>
              }
            />
            <Route
              path="/:prefix?/cloud/office365/subscriptions"
              element={
                <FeatureComponent
                  authRequired
                  showDashboard
                  feature={
                    FeatureDefinitions.Office365_CloudAccountsandSubscriptions
                  }
                  path="/:prefix?/cloud/office365/subscriptions"
                >
                  <Office365Accounts />
                </FeatureComponent>
              }
            />
            <Route
              path="/:prefix?/cloud/office365/invoices"
              element={
                <FeatureComponent
                  authRequired
                  showDashboard
                  feature={FeatureDefinitions.Office365_CloudInvoicing}
                  path="/:prefix?/cloud/office365/invoices"
                >
                  <Office365InvoiceList />
                </FeatureComponent>
              }
            />
            <Route
              path="/:prefix?/cloud/datadog/reports"
              element={
                <FeatureComponent
                  authRequired
                  showDashboard
                  feature={FeatureDefinitions.Datadog_DatadogReports}
                  path="/:prefix?/cloud/datadog/reports"
                >
                  <DatadogReportPage />
                </FeatureComponent>
              }
            />
            <Route
              path="/:prefix?/cloud/tag-groups"
              element={
                <FeatureComponent
                  authRequired
                  showDashboard
                  feature={FeatureDefinitions.Unknown_Unknown}
                  path="/:prefix?/cloud/tag-groups"
                >
                  <TagGroupsPage />
                </FeatureComponent>
              }
            />
            <Route
              path="/:prefix?/cloud/tag-groups/:tagGroupId"
              element={
                <FeatureComponent
                  authRequired
                  showDashboard
                  feature={FeatureDefinitions.Unknown_Unknown}
                  path="/:prefix?/cloud/tag-groups/:tagGroupId"
                >
                  <TagGroupReportsPage />
                </FeatureComponent>
              }
            />
            <Route
              path="/:prefix?/assessments"
              element={
                <FeatureComponent
                  authRequired
                  showDashboard
                  feature={FeatureDefinitions.Unknown_Unknown}
                  path="/:prefix?/assessments"
                >
                  <AssessmentsPage />
                </FeatureComponent>
              }
            />
            <Route
              path="/:prefix?/assessments/:program"
              element={
                <FeatureComponent
                  authRequired
                  showDashboard
                  feature={FeatureDefinitions.Assessments_SecurityPostureReview}
                  path="/:prefix?/assessments/:program"
                >
                  <AssessmentViewPage />
                </FeatureComponent>
              }
            />
            <Route
              path="/:prefix?/assessments/:program/:type"
              element={
                <FeatureComponent
                  authRequired
                  showDashboard
                  feature={FeatureDefinitions.Assessments_SecurityPostureReview}
                  path="/:prefix?/assessments/:program/:type"
                >
                  <AssessmentViewPage />
                </FeatureComponent>
              }
            />
            <Route
              path="/:prefix?/shi-internal/myprojects"
              element={
                <FeatureComponent
                  authRequired
                  showDashboard
                  feature={FeatureDefinitions.SHIInternal_ServicesandProjects}
                  path="/:prefix?/shi-internal/myprojects"
                >
                  <MyProjectList />
                </FeatureComponent>
              }
            />
            <Route
              path="/:prefix?/shi-internal/myaccounts"
              element={
                <FeatureComponent
                  authRequired
                  showDashboard
                  feature={FeatureDefinitions.SHIInternal_AccountManagement}
                  path="/:prefix?/shi-internal/myaccounts"
                >
                  <MyAccountsList />
                </FeatureComponent>
              }
            />
            <Route
              path="/:prefix?/shi-internal/myaccounts/:accountId?"
              element={
                <FeatureComponent
                  authRequired
                  showDashboard
                  feature={FeatureDefinitions.SHIInternal_AccountManagement}
                  path="/:prefix?/shi-internal/myaccounts/:accountId?"
                >
                  <AccountView />
                </FeatureComponent>
              }
            />
            <Route
              path="/:prefix?/shi-internal/contractmapping"
              element={
                <FeatureComponent
                  authRequired
                  showDashboard
                  feature={
                    FeatureDefinitions.SHIInternal_ServiceRequestContractMapping
                  }
                  path="/:prefix?/shi-internal/contractmapping"
                >
                  <ContractMappings />
                </FeatureComponent>
              }
            />
            <Route
              path="/:prefix?/shi-internal/service-request-mapping"
              element={
                <FeatureComponent
                  authRequired
                  showDashboard
                  feature={FeatureDefinitions.SHIInternal_ServiceRequestMapping}
                  path="/:prefix?/shi-internal/service-request-mapping"
                >
                  <ServiceRequestMappings />
                </FeatureComponent>
              }
            />
            <Route
              path="/shi-internal/cisco-reports/renewal"
              element={
                <FeatureComponent
                  authRequired
                  showDashboard
                  path="/shi-internal/cisco-reports/renewal"
                  feature={FeatureDefinitions.SHIInternal_CiscoRenewals}
                >
                  <CiscoRenewals />
                </FeatureComponent>
              }
            />
            <Route
              path="/shi-internal/cisco-reports/iba"
              element={
                <FeatureComponent
                  authRequired
                  showDashboard
                  path="/shi-internal/cisco-reports/iba"
                  feature={FeatureDefinitions.SHIInternal_CiscoIBA}
                >
                  <CiscoIba />
                </FeatureComponent>
              }
            />
            <Route
              path="/:prefix?/support-center/service-report"
              element={
                <FeatureComponent
                  authRequired
                  showDashboard
                  path="/:prefix?/support-center/service-report"
                  feature={
                    FeatureDefinitions.ServiceRequests_AccountServiceRequests
                  }
                >
                  <ServiceReport />
                </FeatureComponent>
              }
            />
            <Route
              path="/:prefix?/shi-internal/docs"
              element={
                <FeatureComponent
                  authRequired
                  showDashboard
                  path="/:prefix?/shi-internal/docs"
                  feature={FeatureDefinitions.Knowledgebase_Knowledgebase}
                >
                  <ArchbeeShiInternal />
                </FeatureComponent>
              }
            />
            <Route
              path="/:prefix?/shi-internal/marketplaceAccounts"
              element={
                <FeatureComponent
                  authRequired
                  showDashboard
                  feature={FeatureDefinitions.SHIInternal_AzureDirectMapping}
                  path="/:prefix?/shi-internal/marketplaceAccounts"
                >
                  <MarketplaceAccounts />
                </FeatureComponent>
              }
            />
            <Route
              path="/:prefix?/labs"
              element={
                <FeatureComponent
                  authRequired
                  showDashboard
                  feature={FeatureDefinitions.Integrations_SHILabs}
                  path="/:prefix?/labs"
                >
                  <Labs />
                </FeatureComponent>
              }
            />
            <Route
              path="/:prefix?/labs/on-demand-requests/management"
              element={
                <FeatureComponent
                  authRequired
                  showDashboard
                  feature={FeatureDefinitions.Integrations_SHILabs}
                  path="/:prefix?/labs/on-demand-requests/management"
                >
                  <UserOnDemandLabsManagement />
                </FeatureComponent>
              }
            />
            <Route
              path="/:prefix?/shi-internal/address-field-test-page"
              element={
                <FeatureComponent
                  authRequired
                  showDashboard
                  feature={FeatureDefinitions.Unknown_Unknown}
                  path="/:prefix?/shi-internal/address-field-test-page"
                >
                  <AddressFieldTestPage />
                </FeatureComponent>
              }
            />
            <Route
              path="/:prefix?/settings/integrations"
              element={
                <FeatureComponent
                  authRequired
                  showDashboard
                  feature={FeatureDefinitions.Integrations_IntegrationSettings}
                  path="/:prefix?/settings/integrations"
                >
                  <Integrations />
                </FeatureComponent>
              }
            />
            <Route
              path="/:prefix?/settings/user-management"
              element={
                <FeatureComponent
                  authRequired
                  showDashboard
                  feature={FeatureDefinitions.Settings_UserManagement}
                  path="/:prefix?/settings/user-management"
                >
                  <UserManagement />
                </FeatureComponent>
              }
            />
            <Route
              path="/:prefix?/settings/team-management/:id?"
              element={
                <FeatureComponent
                  authRequired
                  showDashboard
                  feature={FeatureDefinitions.Settings_SecurityGroups}
                  path="/:prefix?/settings/team-management/:id?"
                >
                  <TeamsManagement />
                </FeatureComponent>
              }
            />
            <Route
              path="/:prefix?/settings/cost-centers"
              element={
                <FeatureComponent
                  authRequired
                  showDashboard
                  feature={
                    FeatureDefinitions.MulticloudConsumption_CostCenterSettings
                  }
                  path="/:prefix?/settings/cost-centers"
                >
                  <CostCenters />
                </FeatureComponent>
              }
            />
            <Route
              path="/:prefix?/settings/:section?"
              element={
                <FeatureComponent
                  authRequired
                  showDashboard
                  path="/:prefix?/settings/:section?"
                  feature={FeatureDefinitions.Settings_Settings}
                >
                  <ReactRouterClassWrapper>
                    {(props) => <Settings {...props} />}
                  </ReactRouterClassWrapper>
                </FeatureComponent>
              }
            />
            <Route
              path="/:prefix?/attachment/:guid"
              element={
                <FeatureComponent
                  authRequired
                  showDashboard
                  feature={FeatureDefinitions.ServiceRequests_AttachmentViewer}
                  path="/:prefix?/attachment/:guid"
                >
                  <ReactRouterClassWrapper>
                    {(props) => <DownloadAttachment {...props} />}
                  </ReactRouterClassWrapper>
                </FeatureComponent>
              }
            />
            <Route
              path="/:prefix?/userprofile"
              element={
                <FeatureComponent
                  authRequired
                  showDashboard
                  path="/:prefix?/userprofile"
                  feature={FeatureDefinitions.Dashboard_UserProfile}
                >
                  <UserProfile />
                </FeatureComponent>
              }
            />
            <Route
              path="/:prefix?/organizationprofile"
              element={
                <FeatureComponent
                  authRequired
                  showDashboard
                  path="/:prefix?/organizationprofile"
                  feature={FeatureDefinitions.Dashboard_OrganizationProfile}
                >
                  <OrganizationProfile />
                </FeatureComponent>
              }
            />
            <Route
              path="/:prefix?/demos"
              element={
                <FeatureComponent
                  authRequired
                  showDashboard
                  feature={FeatureDefinitions.SHIOneDemos_SHIDemos}
                  path="/:prefix?/demos"
                >
                  <DemoAccounts />
                </FeatureComponent>
              }
            />
            <Route
              path="/welcome/:id"
              element={
                <FeatureComponent
                  path={"/welcome/:id"}
                  feature={FeatureDefinitions.Auth_OnboardingWizard}
                >
                  <SetupWizard targetPath="/dashboards/home" />
                </FeatureComponent>
              }
            />
            <Route
              path="/:prefix?/noprofile"
              element={
                <FeatureComponent
                  path={"/:prefix?/noprofile"}
                  feature={FeatureDefinitions.Auth_Auth}
                >
                  <ErrorPage />
                </FeatureComponent>
              }
            />
            <Route
              path="/:prefix?/invalidurl"
              element={
                <FeatureComponent
                  feature={FeatureDefinitions.ErrorHandling_InvalidURL}
                  path="/:prefix?/invalidurl"
                >
                  <InvalidUrlPage />
                </FeatureComponent>
              }
            />
            <Route
              path="/:prefix?/register"
              element={
                <FeatureComponent
                  path={"/:prefix?/register"}
                  feature={FeatureDefinitions.Auth_Auth}
                  noAuth
                >
                  <RegisterPage labelInfo={labelInfo} />
                </FeatureComponent>
              }
            />
            <Route
              path="/:prefix?"
              element={
                <FeatureComponent
                  feature={FeatureDefinitions.Other_Redirect}
                  path="/:prefix?"
                >
                  <Navigate
                    to={{
                      pathname: "/login",
                    }}
                  />
                </FeatureComponent>
              }
            />
            <Route
              path="*"
              element={
                <FeatureComponent
                  path={"*"}
                  feature={FeatureDefinitions.Other_InvalidURL}
                >
                  <div>Invalid URL</div>
                </FeatureComponent>
              }
            />
            <Route
              path="/:prefix?/shi-internal/sample-dashboard-1"
              element={
                <FeatureComponent
                  path={"/:prefix?/shi-internal/sample-dashboard-1"}
                  feature={FeatureDefinitions.Unknown_Unknown}
                  authRequired
                  showDashboard
                >
                  <CustomerExperienceTwoColumnDashboard
                    dashboardId={"sample-dashboard-1"}
                  />
                </FeatureComponent>
              }
            />
            <Route
              path="/:prefix?/shi-internal/sample-dashboard-2"
              element={
                <FeatureComponent
                  path={"/:prefix?/shi-internal/sample-dashboard-2"}
                  feature={FeatureDefinitions.Unknown_Unknown}
                  authRequired
                  showDashboard
                >
                  <CustomerExperienceTwoColumnDashboard
                    dashboardId={"sample-dashboard-2"}
                  />
                </FeatureComponent>
              }
            />
            <Route
              path="/:prefix?/shi-internal/customer-experience-home-dashboard"
              element={
                <Navigate
                  to={{
                    pathname: AppendIfPrefixExists("/home"),
                  }}
                />
              }
            />
            <Route
              path="/:prefix?/home"
              element={
                <FeatureComponent
                  path={"/:prefix?/home"}
                  feature={FeatureDefinitions.Unknown_Unknown}
                  authRequired
                  showDashboard
                >
                  <CustomerExperienceTwoColumnDashboard
                    dashboardId={"customer-experience-home-dashboard"}
                  />
                </FeatureComponent>
              }
            />
            <Route
              path="/:prefix?/support"
              element={
                <FeatureComponent
                  path={"/:prefix?/support"}
                  feature={FeatureDefinitions.Unknown_Unknown}
                  authRequired
                  showDashboard
                >
                  <CustomerExperienceTwoColumnDashboard
                    dashboardId={"customer-experience-support-dashboard"}
                    header={"Support"}
                  />
                </FeatureComponent>
              }
            />
            <Route
              path="/:prefix?/customer-experience-support-dashboard"
              element={
                <Navigate
                  to={{
                    pathname: AppendIfPrefixExists("/support"),
                  }}
                />
              }
            />
            <Route
              path="/:prefix?/services"
              element={
                <FeatureComponent
                  path={"/:prefix?/services"}
                  feature={FeatureDefinitions.Unknown_Unknown}
                  authRequired
                  showDashboard
                >
                  <CustomerExperienceTwoColumnDashboard
                    dashboardId={"customer-experience-services-dashboard"}
                    header={"Services"}
                  />
                </FeatureComponent>
              }
            />
            <Route
              path="/:prefix?/customer-experience-services-dashboard"
              element={
                <Navigate
                  to={{
                    pathname: AppendIfPrefixExists("/services"),
                  }}
                />
              }
            />
            <Route
              path="/:prefix?/shi-internal/customer-experience-cloud-dashboard"
              element={
                <FeatureComponent
                  path={
                    "/:prefix?/shi-internal/customer-experience-cloud-dashboard"
                  }
                  feature={FeatureDefinitions.Unknown_Unknown}
                  authRequired
                  showDashboard
                >
                  <CustomerExperienceTwoColumnDashboard
                    dashboardId={"customer-experience-cloud-dashboard"}
                  />
                </FeatureComponent>
              }
            />
          </Routes>
        </ErrorBoundary>
      </ThemeProvider>
    </EmotionThemeProvider>
  );
}

export default App;
